/* General body styles*/
#root {
  flex-grow: 1;
  display: flex;
  justify-content: center;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 16px;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
}

.App {
  background-color: whitesmoke;
  background-image: url("../public/img/reflex01.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mainPanel {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(36, 48, 141, 0.5);
  padding: 2rem;
  border-radius: 0.5rem;
}

.reviewsPanel {
  min-width:98%;
  width: 98%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(255, 255, 255);
  padding: 2rem;
  border-radius: 0.5rem;
}

.reviewsPanel img {
  width: 5rem;

}

.reviewsPanel a{
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
 }

.reviewsPanel :focus,
.reviewsPanel :hover,
.reviewsPanel :focus a,
.reviewsPanel :hover a {
  border-radius: 0.5rem;
  background-color: #33e0a1;
  color: rgb(0, 0, 0);

}


.Reservations .mainPanel {
  max-height: 100%
}

/*Header and Footer styles*/



.Footer {
  width: 100%;
  background-color: rgb(0, 56, 0);
  color: white;
  padding: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.Footer a:hover,
.Footer a:focus {
  box-shadow: 0px 0px 5px 0.8px #052c0e inset;
}

.Footer a:hover,
.Footer a:focus,
.Footer a:hover p,
.Footer a:focus p {
  background-color: white;
  cursor: pointer;
  color: #333;
}

.Header{
    width: 100%;
    max-height: 10%;
    background-color: rgba(0, 56, 0, 0.644);
    color: white;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;

}



.Header a img{
  margin-left: 0.7rem;
  width: 5rem;
  height: 5rem;
  border-radius:65px;

}

.Header .rowButtonContainer{
  margin-right: 130px;
  width: 100%;
}



.ArtGallery h1,
.Unauthorized h1,
.Missing h1,
.Home h1,
.Terms h1,
.About h1,
.Contact h1,
.NewTour h1,
.EditTour h1,
.Acknowledgement h1 {
  margin: 1rem;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  color: white;
}

.Footer {
  display: flex;
  flex-direction: column;
}

.Footer p {
  color: white;
  margin: 0.4rem;
}

form {
  display: flex;
  flex-direction: column;
}

.textContainer {
  border-radius: 0.25rem;
  padding: 1rem;
  padding-top: 0;
  width: 98%;
  min-width: 100%;
}

.textContainer p {
  text-align: center;
  text-shadow: 1px 1px 3px #202020;
}

.About .textContainer p {
  text-align:left;
}

h2,
h3,
h4 {
  margin: 0.75rem;
}

.h2Home {
  color: white;
  font-size: 1.4rem;
  display: flex;
  justify-content: center;
  text-shadow: 2px 2px 3px #333;
}

.h3Price {
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  text-shadow: 2px 2px 3px #333;
}

.h3Price {
  display: flex;
  justify-content: center;
  text-shadow: 1px 1px 2px #333;
  color: white;
  font-size: 2rem;
}

a,
a:visited {
  color: #000;
  text-decoration: none;
}

.hiddenLabel {
  position: absolute;
  left: -99999px;
}

/* Navbar styles*/
.Nav {
  width: 100%;
  padding-right: 0.5rem;
  background-color: #125522be;
  display: flex;
  box-shadow: 0px -2px 20px 2px #333 inset;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.Nav ul {
  color: white;
  list-style-type: none;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.Nav li {
  padding: 0.4rem;
  color: white;
}

.Nav li a {
  color: whitesmoke;
  text-decoration: none;
}

.Nav li:hover,
.Nav li:focus {
  box-shadow: 0px 0px 2px 0.5px #052c0e inset;
}

.Nav li:hover,
.Nav li:focus,
.Nav li:hover a,
.Nav li:focus a {
  background-color: white;
  cursor: pointer;
  color: #333;
}

/* Search bar styles */

.searchForm {
  justify-content: center;
  width: 100%;
  padding: 1.5rem;
  align-items: center;
}

.searchForm input {
  font-family: monospace, sans-serif;
  width: 50%;
}

.Reviews,
.Home,
.Login,
.Dashboard,
.NewTour,
.EditTour,
.Contact,
.About,
.Terms,
.Missing,
.Booking,
.Reservations,
.Unauthorized, 
.ArtGallery {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 95%;
  max-width: 95%;
  min-height: 645px;
  flex-grow: 1;
  padding: 1.5rem;
}

.Reviews{
  min-height: 195px ;
}
.Booking strong {
  color: white;
}

.cardContainer {

  margin: 1rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-self: center;
}

.panelContainer {
  width: 100%;
  height: 650px;
  display: block;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
}

.dashboardCard1 {
  cursor: pointer;
  font-size: 1.2rem;
  border-radius: 0.5rem;
  margin: 0.5rem;
  box-shadow: 0px -2px 20px 2px #333 inset;
  width: 30%;
  min-width: 280px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  flex-direction: column;
  height: 18rem;
  background-color: rgb(71, 8, 171, 0.5);
}


.Dashboard .tableContainer {
  color: white;
  font-size: 1.5rem;
  font-weight: 700;
  border-radius: 0.5rem;
  width: 95%;
  margin: 0.5rem;
  box-shadow: 0px -2px 20px 2px #333 inset;
  background-color: rgb(71, 8, 171, 0.5);
  padding: 1rem;
}

/* Login style*/
.Login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Login input {
  width: 75%;
}

.Login form {
  width: 50%;
  display: flex;
  align-items: center;
}

.Reservations {
  height: 650px;
  overflow-y: hidden;
}

.Reservations .labelSelect{
  margin: 1rem;
}

.reservation {
  width: 100%;
}

/* Tour links at homepage styles

.tour {
  width: 32%;
  max-width: 32%;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 0.5rem;
}

*/

.tour {
  width: 45%;
  max-width: 50%;
  border-radius: 0.5rem;
  box-sizing: border-box;




  margin: 0.5rem;
}

.tourInfo {
  min-width: 60%;
  padding: 0.25rem;
  text-align: center;
}

.tourInfo .textContainer {
  padding: 0.25rem;
}

.tourInfo h3 {
  margin: 0.25rem;
  margin-bottom: 0.5rem;
}

.tourImage img {
  border-radius: 0.25rem;
  margin: 0.5rem;
  max-width: 90%;
  align-items: center;
  box-shadow: 0 4px 8px 0 #333, 0 6px 20px 0 #333;
}

.Home, 
.Terms,
.About,
.Contact {
  justify-content: start;
  padding-right: 2rem;
  padding-left: 2rem;
}

.About .textContainer {
  color: white;
  border-radius: 0.25rem;
}

.Terms .textContainer {
  color: white;
}

.About a {
  color: white;
  margin-top: 0.5rem;
}

/*
.About {
  height: 650px;
  overflow-x: auto;
}
*/
.Terms h3,
.About h3 {
  color: white;
  text-shadow: 1px 1px 2px #333;
}

.About .buttonContainer {
  display: flex;
  flex-direction: column;
}

.Contact a:hover,
.Contact a:focus,
.About .textContainer a:hover p,
.About .textContainer a:focus p {
  font-weight: bold;
  cursor: pointer;
  color: #27cdff;
  text-shadow: 1px 1px 2px #333;
}


.Contact {

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.Contact .textContainer p {
  font-size: 1rem;
  color: white;
  font-weight: bald;
}

.Contact img {
  margin: 0.8rem;
  width: 1.8rem;
  height: 1.8rem;
}


.textContact {
  width: 100%;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Contact a,
.textContact a {
  color: white;

}

.NewTour form,
.editTourForm {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 90%;
}

/*Tour page styles
old background
*/
.TourPage {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 90%;
  max-width: 90%;
  flex-grow: 1;
  padding: 1rem;
  color: white;
  background-color: rgba(36, 48, 141, 0.5);
}

.tourFullInfo {
  max-width: 45%;
  padding: 0.5rem;
}

.tourFullImage {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}
.tourFullImage img {
  margin: 0.5rem;
  max-width: 100%;
  border-radius: 0.25rem;
  box-shadow: 0 4px 8px 0 #333, 0 6px 20px 0 #333;
}

.tourImage {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}


/*
.tour a {
  background-color: rgba(36, 48, 141, 0.5);
  color: white;
  max-width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  padding: 0.5rem;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: center;
}
*/

.tour a {
min-width: 100%;
  color: white;
  max-width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  padding: 0.5rem;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}


.tour :focus,
.tour :hover,
.tour :focus a,
.tour :hover a {
  background-color: #c1c1ff;
  color: rgb(0, 0, 0);

}




.tourContainer {
  width: 100%;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: rgba(36, 48, 141, 0.5);

  border-radius: 0.5rem;
}



input,
select,
textarea {
  font-family: Arial, Helvetica, sans-serif;
  width: 99%;
  min-height: 45px;
  font-size: 1rem;
  padding: 0.25rem;
  border-radius: 0.25rem;
  margin: 0.35rem;
  outline: none;
}

input[type="date"],
select {
  max-width: 60%;
  margin-left: 1rem;
}

.priceInput input {
  width: 35%;
  text-align: center;
}

.labelSelect {
  width: 45%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: white;
  font-weight: 750;  
  text-shadow: 1px 1px 2px #202020;
}

.labelTextarea {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: white;
  font-weight: 750;

}

.touristDataForm,
.newTourForm,
.editTourForm {
  margin: 0.5rem;
  padding: 0.5rem;
  border-style: solid;
  border-radius: 0.25rem;
  border-color: #c1c1ff;
}

.touristDataForm .buttonContainer {
  display: flex;
  align-items: flex-end;
}

.touristDataForm .buttonContainer button {
  width: 100px;
  min-width: 100px;
  margin: 0.35rem;
}

textarea {
  height: 100px;
  max-width: 100%;
  max-height: 300px;
}

.h1-header {

  display: flex;
  justify-content: center;
  text-shadow: 1px 1px 2px #333;
  color: white;
  
}





.Inactive {
  color: rgb(184, 23, 23);
}

.Missing,
.Unauthorized,
.Acknowledgement {
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.emptySpace {
  min-height: 60%;
}

.Acknowledgement {
  width: 100%;
  background-image: url("../public/img/RedeyedFrog.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  text-align: center;
  text-shadow: 1px 1px 2px #333;
  color: white;
}

.Acknowledgement h1 {
  margin-top: 2rem;
}

/* Button styles*/

button {
  margin: 0.75rem;
  width: 200px;
  height: 45px;
  min-width: 200px;
  border-radius: 0.3rem;
  padding: 0.5rem;
  font-size: 1.1rem;
  cursor: pointer;
  color: white;
  box-shadow: 0 2px 4px 0 #333, 0 3px 10px 0 #333;
}

.saveButton {
  background-color: #7d7de8;
}

.backButton {
  background-color: #627f5a;
}

.buyButton {
  background-color: #27cdff;
  color: #000000;
}

.buttonContainer {
  width: 100%;
  min-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

thead{
  position: sticky;
  top: 0px
}

table {
  width: 100%;
  max-height: 50%;
  border-collapse: collapse;
  overflow-y: hidden;
}
.Reservations .tableContainer{
  overflow-y: auto;
}



th,
td {
  border: 1px solid #333;
  text-align: left;
  padding: 0.25rem;
  font-size: 1rem;
  color: #000;
}

th {
  background-color: #27cdff;
}

td {
  background-color: white;
}

table tbody tr:hover,
table tbody tr:focus,
table tbody tr:hover td,
table tbody tr:focus td {
  border: 1px solid white;
  background-color: #333;
  cursor: pointer;
  color: white;
}

.modalBackground {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalBody {
  border-radius: 0.5rem;
  overflow-y: scroll;
  background-color: white;
  width: 99%;
  max-height: 99%;
  padding: 1.5rem;
  color: #1d1d1d;
  box-shadow: 0 4px 8px 0 #333, 0 6px 20px 0 #333;
  margin-bottom: 1rem;
}

.close-btn {
  margin-left: 1rem;
  color: white;
  cursor: pointer;
  min-width: 5.2rem;
  max-width: 5.2rem;
  min-height: 2.6rem;
  max-height: 2.6rem;
  background-color: #627f5a;
  box-shadow: 0 2px 4px 0 #333, 0 3px 10px 0 #333;
}

.save-btn {
  margin-left: 1rem;
  margin-right: 1rem;
  color: #000;
  cursor: pointer;
  min-width: 5.2rem;
  max-width: 5.2rem;
  min-height: 2.6rem;
  max-height: 2.6rem;
  background-color: #27cdff;
  box-shadow: 0 2px 4px 0 #333, 0 3px 10px 0 #333;
}

.del-btn {
  margin-right: 1rem;
  color: white;
  cursor: pointer;
  min-width: 5.2rem;
  max-width: 5.2rem;
  min-height: 2.6rem;
  max-height: 2.6rem;
  background-color: #ff272b;
  box-shadow: 0 2px 4px 0 #333, 0 3px 10px 0 #333;
}

.modalTitle input {
  width: 100%;
  display: flex;
  align-items: flex-end;
}

.modalContainer {
  border-radius: .5rem;
  width: 400px;
height: 655px;
  background-color: #7d7de8;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  padding-bottom: 20px;
}



/*Hamburger Menu*/

.menuButton {
  display: none;
  margin-right: 1rem;
  flex-direction: column;
  justify-content: space-between;
  width: 1.75rem;
  height: 1.2rem;
}

.menuButton .bar {
  height: 3px;
  width: 100%;
  background-color: white;
  border-radius: 5px;
}

.editOrderForm {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.editOrderForm textarea {
  height: 55px;
  min-width: 99%;
}

.bookingForm{
  width: 80%;
}

.touristDataForm .labelSelect .rowButtonContainer{
  display: flex;
}





/*Media Queries*/

@media all and (max-width: 650px) {

  .h1-header{
    font-size: 1.5rem;
  }

  .Header a img{
    margin-left:0.7rem;
    width: 5rem;
    height: 5rem;
    border-radius:65px;
  }

  .Header .rowButtonContainer{
    margin-right: 65px;
    width: 100%;
  }
  


  .searchForm {
    width: 100%;
    padding: 1rem;
  }
  .searchForm input {
    min-height: 2rem;
  }

  .textContainer {
    display: block;
  }

  .tour {
    width: 100%;
    min-width: 100%;
  }
  .tourImage {
    max-width: 90%;
  }

  .navHeader {
    display: flex;
    justify-content: right;
    align-items: center;
  }

  .menuButton {
    display: flex;
  }

  .Nav {
    display: block;
    padding: 0.4rem;
  }

  .navUl ul {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }

 
  .navUl a {
    width: 100%;
    text-align: center;
    padding: 0;
  }

  .Login,
  .TourPage,
  .Booking {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 0.5rem;
  }
  .bookingForm{
    width: 100%;
  }

  .tourFullImage {
    width: 95%;
  }

  .tourFullInfo {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: none;
  }

  .tourFullInfo .textContainer {
    width: 100%;
    max-height: 80%;
  }

  .TourPage .buttonContainer,
  .Booking .buttonContainer,
  .NewTour .buttonContainer,
  .EditTour .buttonContainer {
    display: flex;
    flex-direction: column;
  }

  .Booking .touristDataForm .rowButtonContainer {
    width: 100%;
    min-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }



  .labelSelect {
    width: 100%;
    flex-direction: column;
  }


  
  .touristDataForm .labelSelect{
    width: 100%;
  }

  .Contact {
    flex-direction: column;
    align-items: center;
  }

  .emailForm,
  .textContact {
    width: 100%;
  }

  .Login .loginForm {
    width: 100%;
  }

  .NewTour,
  .EditTour {
    width: 100%;
    max-width: 100%;
    padding: 0.5rem;
    margin: 0;
  }

  .newTourForm,
  .editTourForm {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .editOrderForm {
    width: 100%;
  }

  .Home,
  .Terms,
  .About, 
  .Contact,
  .Reviews {
    padding: 0.5rem;
  }

  .Reservations {
    width: 100%;
    padding: 0.25em;
    align-items: center;
  }

  .tourFullInfo .h3Price {
    font-size: 1.5rem;
  }

  .tableContainer {
    width: 100%;
    overflow-y: auto;
  }

  tbody {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  th {
    display: none;
  }

  td {
    padding: 0.4rem;
    align-items: stretch;
  }

  tr {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
  }

  td::before {
    content: attr(data-cell) ":  ";
    font-weight: 700;
    text-transform: capitalize;
  }

  .Dashboard {
    padding: 0.5rem;
  }


  .Dashboard .tableContainer {
    border-radius: 0.5rem;
    width: 95%;
    margin: 1rem;
    background-color: rgb(71, 8, 171, 0.5);
    padding: 0.5rem;
  }

  .dashboardCard1 {
    width: 95%;
  }

  .reviewsPanel,
  .mainPanel {
    padding: 0.5rem;
  }



  .Reservations .searchForm input {
    width: 100%;
  }

  .Reservations .mainPanel {
    max-height: 650px;
  }
}